<div class="projectable-form-wrapper verify-certificate-wrapper"
     (keyup.enter)="save($event)"
     (keyup.escape)="closeModal(false)"
     tabindex="0"
>
    <lib-form-header
        [title]="'Verify Certificate for CA:'"
        [data]="formData"
        [moreActions]="formData.moreActions"
        (actionRequested)="headerActionRequested($event)"
        [(formView)]="formView"
        [saveDisabled]="svc.saveDisabled || formDataInvalid"
        [showHeaderToggle]="false"
        [actionButtonText]="'Verify'"
    ></lib-form-header>
    <div class="verify-certificate-container projectable-form-container">
        <div class="projectable-form-main-column form-group-row" [hidden]="formView !== 'simple'">
            <div class="form-group-column">
                <lib-form-field-container
                    [title]="'Instructions'"
                >
                    <ul class="instructions-list">
                        <li>Generate a certificate with the common name CN=<b>{{formData.verificationToken}}</b></li>
                        <li>Upload the generated cert, or copy & paste it using the text field below</li>
                        <li>Click the "Verify" button to submit certificate for verification</li>
                    </ul>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Token'"
                >
                    <div class="copy-token-container" (click)="copyToClipboard(formData.verificationToken)">
                        <span class="form-field-input copy-token-button" matTooltip="Copy verifcation token" matTooltipPosition="below" matTooltipShowDelay="300">{{formData.verificationToken}}</span>
                        <div class="copy-icon"></div>
                    </div>
                </lib-form-field-container>
                <lib-form-field-container
                        [title]="'Certificate'"
                        [label]="'REQUIRED'"
                >
                    <span *ngIf="errors['certPem']" class="cert-error-text error-text">{{certErrorMessage}}</span>
                    <div class="form-field-input-group">
                        <div class="form-field-label-container select-file-label-container">
                            <div class="form-field-header">
                                <div class="form-field-title-container">
                                    <span class="form-field-title">Enter PEM</span>
                                </div>
                                <span class="select-file-button" (click)="openFileSelect($event)">
                                        Select File
                                        <div class="spinner" *ngIf="fileSelectOpening"></div>
                                    </span>
                                <input #fileSelect type="file" style="display:none" (change)="selectPemFile($event)">
                            </div>
                            <textarea placeholder="Paste pem contents or select file" [(ngModel)]="certificate" [ngClass]="{error: errors['certPem']}"></textarea>
                        </div>
                    </div>
                </lib-form-field-container>
                <div class="buttons-container buttons">
                    <div class="linkButton cancel" (click)="returnToListPage()">Oops, no get me out of here</div>
                    <div class=" save-button button save confirm" (click)="save($event)">Verify</div>
                </div>
            </div>
        </div>
    </div>
</div>
<lib-loading-indicator *ngIf="isLoading" [isLoading]="isLoading"></lib-loading-indicator>
