<div class="config-type-form-container projectable-form-wrapper"
     (keyup.enter)="save($event)"
     (keyup.escape)="closeModal(false)"
     tabindex="0"
>
    <lib-form-header
            [data]="formData"
            [title]="(formData.id ? 'Edit Config Type' : 'Create Config Type')"
            [moreActions]="formData.moreActions"
            (actionRequested)="headerActionRequested($event)"
            [(formView)]="formView"
            [saveDisabled]="saveDisabled"
            [saveTooltip]="saveButtonTooltip"
    ></lib-form-header>
    <div class="config-form-container projectable-form-container">
        <div class="projectable-form-main-column form-group-row" [hidden]="formView !== 'simple'">
            <div class="form-group-column three-fifths">
                <lib-form-field-container [title]="'Config Name'" [label]="'Required'">
                    <input
                        id="ConfigName"
                        type="text"
                        maxlength="500"
                        placeholder="Name this config"
                        [ngClass]="{error: errors['name']}"
                        [(ngModel)]="formData.name"
                    >
                </lib-form-field-container>
                <lib-form-field-container [title]="'Schema'" [label]="'Required'" class="schema-json-editor">
                    <lib-json-view [(data)]="formData.schema" [jsonInvalid]="errors['schema']"></lib-json-view>
                </lib-form-field-container>
                <lib-form-field-toggle [(toggleOn)]="showMore" (toggleOnChange)="showMoreChanged($event)" style="margin: 0px 10px"></lib-form-field-toggle>
                <div [hidden]="!showMore" class="form-group-column">
                    <lib-form-field-container
                            [title]="'Custom Tags'"
                            [label]="'OPTIONAL'"
                            class="form-field-advanced"
                            *ngIf="!hideTags"
                    >
                        <lib-custom-tags [(tags)]="formData.tags"></lib-custom-tags>
                    </lib-form-field-container>
                </div>
            </div>
            <div class="form-group-column two-fifths">
                <lib-form-field-container
                        [title]="'API Calls'"
                        [label]="''"
                        [headerActions]="apiOptions"
                        (actionRequested)="apiActionRequested($event)"
                >
                    <div class="form-row">
                        <input class="form-field-input" [value]="apiCallURL" readonly/>
                        <div class="url-copy icon-copy copy" (click)="copyToClipboard(apiCallURL)"></div>
                    </div>
                    <lib-json-view *ngIf="formData" [(data)]="apiData" [readOnly]="true" [showCopy]="true"></lib-json-view>
                </lib-form-field-container>
            </div>
        </div>
        <div class="form-group-column" *ngIf="formView === 'raw'">
            <lib-json-view *ngIf="formData" [(data)]="formData"></lib-json-view>
        </div>
    </div>
</div>
<lib-loading-indicator *ngIf="isLoading" [isLoading]="isLoading"></lib-loading-indicator>
